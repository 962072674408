import React from 'react';
import Layout from "../../layout/Layout";
import PageHeaderBlock from "../../blocks/PageHeaderBlock";
import SubmitApplicationBlock from "../../blocks/static/SubmitApplicationBlock";
import InfoCardsBlock from "../../blocks/InfoCardsBlock";
import SEO from "../../components/seo";

import enMessages from '../../i18n/payments/en.js'
import ruMessages from '../../i18n/payments/ru.js'
import '../../styles/pages/payments.scss';

const messages = {
    en: enMessages,
    ru: ruMessages
}

const PaymentsPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />
            <PageHeaderBlock
                locale={locale}
                title={messages[locale].pageHeaderBlock.title}
                text={messages[locale].pageHeaderBlock.text}
            />
            <InfoCardsBlock
                cardsTitle={messages[locale].infoCardsBlock.cardsTitle}
                cardsTitleColor="black"
                cardsLayout="column"
                config={{
                    animation: '2',
                    baseBorder: false
                }}
                cards={messages[locale].infoCardsBlock.cards}
            />
            <InfoCardsBlock
                cardsTitle={messages[locale].infoCardsBlockFeatures.cardsTitle}
                cardsTitleColor="black"
                cardsLayout="rowString"
                config={{
                    animation: '2',
                    baseBorder: true
                }}
                cards={messages[locale].infoCardsBlockFeatures.cards}
            />
            <InfoCardsBlock
                cardsTitle={messages[locale].infoCardsBlockSecond.cardsTitle}
                cardsTitleColor="grey"
                cardsLayout="column"
                cards={messages[locale].infoCardsBlockSecond.cards}

                config={{
                    animation: '2',
                    baseBorder: false
                }}
            />
            <InfoCardsBlock
                listTitle={messages[locale].infoCardsBlockThird.listTitle}
                listColor="black"
                list={messages[locale].infoCardsBlockThird.list}
                listGoTo={messages[locale].infoCardsBlockThird.listGoTo}
            />
            <SubmitApplicationBlock
                additionalStyle="PaymentsBottomFOSsmallWithoutTitle"
                title={messages[locale].submitApplicationBlock.title}
                textSubmit={messages[locale].submitApplicationBlock.textSubmitBottom || messages[locale].submitApplicationBlock.textSubmit}
            />
        </Layout>
    )
};

export default PaymentsPage;
