import iconCard from "../../images/icons/styled/second/Card.svg";
import iconSBP from "../../images/icons/styled/second/SBP.svg";
import iconHeart from "../../images/icons/styled/first/Heart.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconLightning from "../../images/icons/styled/first/Lightning.svg";
import iconSplitPayments from "../../images/icons/styled/first/splitPayments.svg";
import iconSimpleForBuyer from "../../images/icons/styled/first/iconSimpleForBuyer.svg";
import iconSimpleForCompany from "../../images/icons/styled/second/iconSimpleForCompany.svg";

import links from "../../utils/links";


export default {
    "seo": {
        "title": "Интернет-эквайринг для бизнеса | Банк 131",
        "description": "Подключайте прием оплаты на сайте банковскими картами и с помощью QR-кодов. Visa, MasterCard, МИР. Оплата в один клик. Мультирасчёты для маркетплейсов и онлайн-площадок"
    },
    "pageHeaderBlock": {
        "title": "Принимайте платежи",
        "text": "Принимайте оплату банковскими картами, по QR-коду <br />или с помощью платежных ссылок."
    },
    "infoCardsBlock": {
        "cardsTitle": "Используйте удобные платежные методы для вашего бизнеса",
        "cards": [
            {
                "title": 'Банковские карты ',
                "titleStyle": "small",
                "icon": iconCard,
                "theme": 'withoutBackground',
                "text": "Принимайте платежи банковскими картами Visa, MasterCard, МИР и получайте деньги <nobr>на счет в любом банке</nobr>"
            },
            {
                "title": 'Система быстрых платежей',
                "titleStyle": "small",
                "icon": iconSBP,
                "theme": 'withoutBackground',
                "text": "Принимайте оплату с помощью QR-кодов <nobr>и платежных ссылок:</nobr> на сайте и в мобильных приложениях ",
                "cardGoTo": {
                    "to": "/products/sbp",
                    "text": "Подробнее"
                },
            },
        ]
    },
    "infoCardsBlockFeatures": {
        "cards": [
            {
                "title": 'Просто для покупателя ',
                "titleStyle": "small ProductCard-TitleOnlinebank",
                "icon": iconSimpleForBuyer,
                "theme": 'withoutBackgroundRow',
                "productCardContentClass": 'Flex-column-left ProductCard-Content-Full',
                "text": "",
                "list": [
                    "Оплата в один клик – без повторного ввода данных",
                    "Полные и частичные возвраты",
                    "Быстрая безопасная оплата на сайте и в приложениях",
                ],
            },
            {
                "title": 'Удобно для компании',
                "titleStyle": "small ProductCard-TitleOnlinebank",
                "icon": iconCard,
                "theme": 'withoutBackgroundRow',
                "productCardContentClass": 'Flex-column-left ProductCard-Content-Full',
                "text": "",
                "list": [
                    "Холдирование и рекурренты",
                    "Мультирасчеты для маркетплейсов и онлайн площадок",
                    "Перечисление в рублях на банковские счета в России и за рубежом",
                ],
                "listStyle": "greenList"
            },
        ]
    },
    "infoCardsBlockSecond": {
        "cardsTitle": "Вам понравится",
        "cards": [
            {
                "title": 'Сплитование платежей',
                "titleStyle": "small",
                "text": 'Удобное решение для маркетплейсов и сервисов, если нужно отправить денежные средства сразу на несколько счетов. Делите платежи между разными получателями: поставщиками и партнерами.',
                "icon": iconSplitPayments,
                "description": '',
                "theme": 'withoutBackground',
                "cardGoTo": {
                    "to": "/products/marketplaces",
                    "text": "Подробнее"
                },
            },
            {
                "title": 'Забота о безопасности',
                "titleStyle": "small",
                "text": 'Все запросы к API шифруются, а защита платежной формы обеспечивается в соответствии стандартам PCI-DSS Level 1.',
                "icon": iconShield,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Быстрая интеграция по API',
                "titleStyle": "small",
                "text": 'Современные и удобные API и SDK, гибкие схемы интеграции, тестирование до начала работы с реальными данными. Если не хватит возможностей – пишите, обсудим.',
                "icon": iconLightning,
                "description": '',
                "theme": 'withoutBackground'
            }
        ]
    },
    "infoCardsBlockThird": {
        "listTitle": "Как подключить прием платежей",
        "list": [
            `Отправить заявку на почту <a href="mailto:partners@131.ru" target="_blank" rel="noreferrer">partners@131.ru</a> или оставить свой контакт в форме обратной связи ниже`,
            `Подписать договор и начать интеграцию по <a href="${links.ru.api}" target="_blank" rel="noreferrer">нашему API</a>`,
            'Готово – теперь вам доступны выплаты и прием платежей через СБП '
        ],
    },
    "submitApplicationBlock": {
        "textSubmit": "Подключиться"
    }
}
