import iconCard from "../../images/icons/styled/second/Card.svg";
import iconSBP from "../../images/icons/styled/second/SBP.svg";
import iconHeart from "../../images/icons/styled/first/Heart.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconLightning from "../../images/icons/styled/first/Lightning.svg";
import iconSplitPayments from "../../images/icons/styled/first/splitPayments.svg";
import iconSimpleForBuyer from "../../images/icons/styled/first/iconSimpleForBuyer.svg";
import iconSimpleForCompany from "../../images/icons/styled/second/iconSimpleForCompany.svg";

import links from "../../utils/links";

export default {
    "seo": {
        "title": "Payment service: sell and accept payments online  | Bank 131",
        "description": "Enable bank card and QR code payments. Visa, MasterCard, MIR. One-click payments. Split payments for online platforms and marketplaces."
    },
    "pageHeaderBlock": {
        "title": "Accept payments",
        "text": "Accept payments made with bank cards and via QR codes and payment links."
    },
    "infoCardsBlock": {
        "cardsTitle": "Use efficient payment methods for your business",
        "cards": [
            {
                "title": 'Bank cards ',
                "titleStyle": "small",
                "icon": iconCard,
                "theme": 'withoutBackground',
                "text": "Accept payments made with Visa, MasterCard, and MIR bank cards and receive money to your account in any bank"
            },
            {
                "title": 'Faster payments system',
                "titleStyle": "small",
                "icon": iconSBP,
                "theme": 'withoutBackground',
                "text": "Accept payments made via QR codes and payment links on websites and mobile applications",
                "cardGoTo": {
                    "to": "/en/products/sbp",
                    "text": "Learn more"
                },
            },
        ]
    },
    "infoCardsBlockFeatures": {
        "cards": [
            {
                "title": 'Facilitate buying ',
                "titleStyle": "small ProductCard-TitleOnlinebank",
                "icon": iconSimpleForBuyer,
                "theme": 'withoutBackgroundRow',
                "productCardContentClass": 'Flex-column-left ProductCard-Content-Full',
                "text": "",
                "list": [
                    "One-click payments without reentering payment data",
                    "Full and partial refunds",
                    "Quick and secure payments on websites and mobile apps",
                ],
            },
            {
                "title": 'Business benefits',
                "titleStyle": "small ProductCard-TitleOnlinebank",
                "icon": iconCard,
                "theme": 'withoutBackgroundRow',
                "productCardContentClass": 'Flex-column-left ProductCard-Content-Full',
                "text": "",
                "list": [
                    "Funds on hold and recurrent payments",
                    "Split payments for marketplaces and online platforms",
                    "Rubble transfers to bank accounts in Russia and abroad",
                ],
                "listStyle": "greenList"
            },
        ]
    },
    "infoCardsBlockSecond": {
        "cardsTitle": "You will like it",
        "cards": [
            {
                "title": 'Split payments',
                "titleStyle": "small",
                "text": 'This is an excellent solution for marketplaces and services where you need to send money to several accounts simultaneously. You can split payments between different recipients – your vendors and buyers.',
                "icon": iconSplitPayments,
                "description": '',
                "theme": 'withoutBackground',
                "cardGoTo": {
                    "to": "/en/products/marketplaces",
                    "text": "Learn more"
                },
            },
            {
                "title": 'Security',
                "titleStyle": "small",
                "text": 'All requests made to the API are encrypted, and the payment system is protected according to PCI-DSS Level 1.',
                "icon": iconShield,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Quick API integration',
                "titleStyle": "small",
                "text": 'We offer modern and convenient API and SDK, flexible integration schemes, and testing before working with real data. If you need more features – let us know about them.',
                "icon": iconLightning,
                "description": '',
                "theme": 'withoutBackground'
            }
        ]
    },
    "infoCardsBlockThird": {
        "listTitle": "Getting started",
        "list": [
            `Send your application to <a href="mailto:partners@131.ru" target="_blank" rel="noreferrer">partners@131.ru</a> or leave your contact through the feedback form below`,
            `Sign the contract and initiate <a href="${links.ru.api}" target="_blank" rel="noreferrer">API integration</a>`,
            'Great! Now you can accept payments '
        ],
    },
    "submitApplicationBlock": {
        "textSubmit": "Get in touch"
    }
}
